import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Fb from "../../assets/media/fb.svg";
import Insta from "../../assets/media/insta.svg";
import Linkedin from "../../assets/media/linkedin.svg"
import Website from "../../assets/media/website.svg"
import Twitter from "../../assets/media/twitter.svg"
import Next from "../../assets/media/next.svg"
import QRCode from "qrcode.react";
import "../../assets/css/public.css"
function NewTemplate() {
  const [userData, setUserData] = useState({});
  const { userName } = useParams();
  const cleanedUserName = userName.substring(1);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.multigrad.in/secure/public-user/${cleanedUserName}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setUserData(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [userName]);

  const renderIcon = (url, imageSrc, altText, type) => {
    if (!url) return null;

    let finalUrl = url;

    // Conditionally construct URL based on social media type
    switch (type) {
      case 'linkedin':
        if (!url.startsWith('https://') && !url.startsWith('http://')) {
          finalUrl = `https://www.linkedin.com/in/${url}`;
        }
        break;
      case 'twitter':
        if (!url.startsWith('https://') && !url.startsWith('http://')) {
          finalUrl = `https://twitter.com/${url}`;
        }
        break;
      case 'instagram':
        if (!url.startsWith('https://') && !url.startsWith('http://')) {
          finalUrl = `https://instagram.com/${url}`;
        }
        break;
      case 'facebook':
        if (!url.startsWith('https://') && !url.startsWith('http://')) {
          finalUrl = `https://facebook.com/${url}`;
        }
        break;
      case 'website':
        if (!url.startsWith('https://') && !url.startsWith('http://')) {
          finalUrl = `https://${url}`;
        }
        break;
      default:
        finalUrl = url;
    }

    return (
      <div className={`socialIcon ${userData.userName}`}>
        <a href={finalUrl} target="_blank">
          <img src={imageSrc} alt={altText} />
        </a>
      </div>
    );
  };
  return (
    <div className="pub">
      <Helmet>
        <meta
          name="description"
          content={`Hi Linkedin Family, checkout my multigrad profile. Let's connect with and fun together..! Download the multigrad app https://app.multigrad.in/${userData.userName}   #multigradApp #multigrad`}
        />
        <meta property="og:image" content={userData.userProfile} />
        <meta
          property="og:description"
          content={`Hi Linkedin Family, checkout my multigrad profile. Let's connect with and fun together..! Download the multigrad app https://app.multigrad.in/${userData.userName}   #multigradApp #multigrad`}
        />
        <meta
          property="og:url"
          content={`https://app.multigrad.in/${userData.userName}`}
        />
      </Helmet>
<div className="mg-banner" id="publiProf">


     <div className={`mg-portfolio ${userData.userName}`}>
       <div className={`profile-image ${userData.userName}`}>
         <img src={userData.userProfile} alt={userData.userName}/>
         <h2>{userData.fullName}
         {userData.userVerified === 'yes' && 
          <img src="https://api.multigrad.in/open/media/?file=1710452606148.jpeg" id="verification" alt={userData.fullName} />
  }
          </h2>
         <p>{userData.userType}</p>
       </div>
       
       <div className={`mg-social-media ${userData.userName}`}>
  
      
       {renderIcon(userData.userFacebook, Fb, 'Facebook', 'facebook')}
      {userData.userName && renderIcon(userData.userLinkedin, Linkedin, 'LinkedIn', 'linkedin')}
      {renderIcon(userData.userInstagram, Insta, 'Instagram', 'instagram')}
      {renderIcon(userData.userTwitter, Twitter, 'Twitter', 'twitter')}
      {renderIcon(userData.userWebsite, Website, 'Website', 'website')}
    
     
        
       </div>

       <div  className={`education ${userData.userName}`}>
       <h4>Education</h4>
            <div className="education-details">
            
              <div className="ed-de">
                <img />
                <h1>{userData.classOrCourse}  |  <span>{userData.stream}</span></h1>
              </div>
             
            </div>
       </div>
       
       <div  className={`education ${userData.userName}`}>
       {(userData.skills && userData.skills.length >0)  &&
        <>
       <h4>Skills</h4>
       <div  className={`skills ${userData.userName}`}>
     
       {userData.skills && userData.skills.map((skill, index) => (
  <div key={index} className="skills-dev">
    {skill}
  </div>
))}

       </div>
       </>
}
{userData.certificates && userData.certificates.length > 0 && (
    <>
        <h4>Achivements </h4>
        <div className="cert-dev">
            {userData.certificates.map((certificate, index) => {
                // Extracting numbers from the certificate string
                const certificateNumber = certificate.match(/\d+/)[0];
                // Removing leading zeros by converting to number and back to string
                const trimmedCertificateNumber = Number(certificateNumber).toString();

                return (
                    <a key={index} href={`https://certificates.multigrad.in/${trimmedCertificateNumber}`} target="_blank">
                      {userData.certificates}
                    </a>
                );
            })}
        </div>
    </>
)}

{userData.certificates && userData.certificates.length <= 0 && (
  <>
    <h4>Achivements </h4>
  <div className="cert-dev">
    No Achivements found!
</div>
  </>

)}
       </div>
       <div className="connect">
        <h3>Let's work together</h3>
        <p>Get in touch on multigrad app. See how we can grow together!</p>
        <QRCode value={`https://multigrad.in/${userData.userName}`} id="qrcodep" />
          <a href={`https://play.google.com/store/apps/details?id=com.multigrad.app`}>Connect me <img src={Next} alt="connect" /></a>
     </div>

     
     </div>
     
     </div>
     <div className="copyright">
       <a href=''>2024 | Multigrad | All rights reserved</a>
     </div>
     <div className="template">
       <a href="https://play.google.com/store/apps/details?id=com.multigrad.app">Made on Multigad</a>
     </div>
    </div>
  );
}

export default NewTemplate;
