import React from 'react';
import Users from '../../../assets/2.0/media/userssvg.svg'
import Mentors from '../common/Mentors';
import ButtonTop from '../common/Button';
import Clubs from '../common/Clubs';
import Rocket from '../../../assets/2.0/media/rocket.svg';
import ButtonBottom from '../common/ButtonBottom';
const Clubs2 = () => {
    const handleStartFree = () => {
        window.open("https://play.google.com/store/apps/details?id=com.multigrad.app", '_blank');
    };

  return (
    <>
    
    <div className='home-container'>
        <div className='container line-container'>
            <div className='tagline'>
                <h1><span id="white">Join</span> your favourite <span id="white">Club</span></h1>
                <p>"Discover Your Passion, Join Your Community!"- Multigrad</p>
                 <ButtonTop />
                <div className='btn-two'>
                    <img src={Users}/>
                    <p>10,000+ students across india using multigrad!</p>
                </div>
            </div>
        </div>
    </div>
    <div className='highlighted multi'>
            <div className='container'>
            <h1><span id="white">Discover Club</span>!</h1>
                     <Clubs />
            </div>
       
        </div>
        <div className='footer' >
            <div className="highlighted">
                <div className='container'>
                    <div className='flex-mobile flex-row'>
                    <div className='mg-60'>
                    <h1>Want to start your  <br /><span id="white">own club?</span></h1>
                   <ButtonBottom />
                </div>
                <div className='mg-40'>
                    <img src={Rocket} />
                </div>      
                </div>
                </div>
            </div>
         </div>

     
    </>
   
  );
};

export default Clubs2;
