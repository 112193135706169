import React from 'react';
import Users from '../../../assets/2.0/media/userssvg.svg'
import Mentors from '../common/Mentors';
import ButtonTop from '../common/Button';
const Mentorship2 = () => {
  return (
    <>
    
    <div className='home-container'>
        <div className='container line-container'>
            <div className='tagline'>
                <h1>Find the right <span id="white">Mentor</span></h1>
                <p>Get Personalized advice from top experts to your specific questions - Multigrad</p>
                <div className='btn-two'>
                   <ButtonTop />
                </div>
                <div className='btn-two'>
                    <img src={Users}/>
                    <p>10,000+ students across india using multigrad!</p>
                </div>
            </div>
        </div>
    </div>
    <div className='highlighted multi'>
            <div className='container'>
            <h1>Top 1% <span id="white">Experts </span>!</h1>
                     <Mentors />
            </div>
       
        </div>
    </>
   
  );
};

export default Mentorship2;
