import React from 'react';

const ButtonTop = () => {
    const handleStartFree = () => {
        window.open("https://play.google.com/store/apps/details?id=com.multigrad.app", '_blank');
    };
    const joinCommunity = () => {
        window.open("https://chat.whatsapp.com/KSZ38cardc5DwGCRqkebmI", '_blank');
    };




  return (
   <>
    <div className='btn-two'>
                    <button id="primary-btn" onClick={handleStartFree}>Start for free</button>
                    <button id="secondary-btn" onClick={joinCommunity}>Join community</button>
                </div>
   </>
  );
};

export default ButtonTop;
