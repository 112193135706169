import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Users from '../../../assets/2.0/media/userssvg.svg';
import CM from '../../../assets/2.0/media/cm.svg';
import Start from '../common/Start';
import ButtonTop from '../common/Button';
import Upcoming2 from '../common/Upcoming';


import { FaLaptopCode } from "react-icons/fa";
import { GiTeacher } from "react-icons/gi";
import { MdSecurity } from "react-icons/md";
import { MdOutlineWorkOutline } from "react-icons/md";
import GLAButton from './GLAButton';
import GLAStart from './GLAstart';

const EventPage = () => {
  const [hackathons, setHackathons] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.multigrad.in/open/mentors');
        setHackathons(response.data.data);
      } catch (error) {
        console.error('Error fetching hackathons:', error);
      }
    };

    fetchData();
  }, []);

  const openWhatsAppGroup = () => {
    window.open('https://play.google.com/store/apps/details?id=com.multigrad.app', '_blank');
  };
  useEffect(() => {
    const loadTallyScript = () => {
      const script = document.createElement('script');
      script.src = 'https://tally.so/widgets/embed.js';
      script.async = true;
      script.onload = () => {
        // Check if Tally is defined before attempting to call it
        if (window.Tally) {
          window.Tally.loadEmbeds();
        }
      };
      script.onerror = () => {
        console.error('Failed to load Tally script');
      };
      document.body.appendChild(script);
    };

    loadTallyScript();
  }, []);

  return (
    <>
     <div className='home-container'>
        <div className='container line-container'>
            <div className='tagline'>
               <img src="https://upload.wikimedia.org/wikipedia/en/4/42/GLA_University_logo.png" className='glacam' />
                <h1><span id="white">Building the Next Generation of Cyber Experts</span></h1>
                <p>Navigate your college journey seamlessly - Explore, Learn, Placed! - Multigrad</p>
               <GLAButton />
                <div className='btn-two'>
                    <img src={Users}/>
                    <p>1,000+ students across india registered for event!</p>
                </div>
           
            </div>
        </div>
   
       

        <div className='highlighted white' id="register">
        <h1>Register for event!</h1>
        <iframe
        data-tally-src="https://tally.so/embed/mZlNP0?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
        loading="lazy"
        width="100%"
        height="686"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        title="Registration form"
      ></iframe>

        </div>

       
        <div className='container upcoming'>
       
       <h2>Our Sponsors</h2>
           
           
           <>
           <div  className='mt-div contain'>
             <img src={`https://api.multigrad.in/open/media/?file=1723147435608.png`} alt={``} />
            
          
           </div>
           <div  className='mt-div contain'>
             <img src={`https://api.multigrad.in/open/media/?file=1723147455975.png`} alt={``} />
            
          
           </div>
           <div  className='mt-div contain'>
             <img src={`https://api.multigrad.in/open/media/?file=1723147473446.jpg`} alt={``} />
            
          
           </div>
           <div  className='mt-div contain'>
             <img src={`https://api.multigrad.in/open/media/?file=1723099179068.png`} alt={``} />
            
          
           </div>
           <div  className='mt-div contain'>
             <img src={`https://api.multigrad.in/open/media/?file=1723147492367.jpg`} alt={``} />
            
          
           </div>
           </>
       
    
       
     </div>
     

     <GLAStart />
        
    </div>
    
   
   
      
    </>
  );
};

export default EventPage;
