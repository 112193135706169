import React from "react";
import Container from "react-bootstrap/Container";
import Users from '../../../assets/2.0/media/userssvg.svg';
import ButtonTop from "../common/Button";
function Privacy2() {
  return (
    <div className='home-container'>
         <div className='container line-container'>
            <div className='tagline'>
                <h1><span id="white">Privacy Policy</span></h1>
               
            </div>
        </div>

       
<div className="highlighted white">
        <div className="container">
          <h2>Privacy Policy</h2>
          <p>
            At Multigrad, accessible from https://multigrad.in, one of our main
            priorities is the privacy of our visitors. This Privacy Policy
            document contains types of information that is collected and
            recorded by Multigrad and how we use it.
          </p>
          <p>
            If you have additional questions or require more information about
            our Privacy Policy, do not hesitate to contact us.
          </p>
          <p>
            This Privacy Policy applies only to our online activities and is
            valid for visitors to our website with regards to the information
            that they shared and/or collect in Multigrad. This policy is not
            applicable to any information collected offline or via channels
            other than this website.
          </p>
          <h2>Consent</h2>
          <p>
            By using our website, you hereby consent to our Privacy Policy and
            agree to its terms.
          </p>
          <h2>Information we collect</h2>
          <p>
            The personal information that you are asked to provide, and the
            reasons why you are asked to provide it, will be made clear to you
            at the point we ask you to provide your personal information.
          </p>
          <p>
            If you contact us directly, we may receive additional information
            about you such as your name, email address, phone number, the
            contents of the message and/or attachments you may send us, and any
            other information you may choose to provide.
          </p>
          <p>
            When you register for an Account, we may ask for your contact
            information, including items such as name, company name, address,
            email address, and telephone number.
          </p>
        </div>
        </div>
      </div>
    
  );
}

export default Privacy2;
