import React,{useContext} from 'react';
import Users from '../../../assets/2.0/media/userssvg.svg';
import { JobContext } from '../context/JobContext.js';
import { MdOutlineWorkOutline } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import Com from '../../../assets/2.0/media/companies.svg';
import Start from '../common/Start.js';
import ButtonTop from '../common/Button.js';
const Internship2 = () => {
    const jobs = useContext(JobContext);
    
  return (
    <>
     <div className='home-container'>
        <div className='container line-container'>
            <div className='tagline'>
                <h1>Find your dream <span id="white">job or internship</span> now</h1>
                <p>Navigate your college journey seamlessly - Explore, Learn, Placed! - Multigrad</p>
                 <ButtonTop />
                <div className='btn-two'>
                    <img src={Users}/>
                    <p>10,000+ students across india using multigrad!</p>
                </div>
            </div>
        </div>
        <div className='footer' >
            <div className="highlighted white">
               <div className='container jobs-div'>
                  <div className='companieslog'>
                     <h3>Get a job in 2000+ top product and service based companies globally</h3>
                    
                  </div>
               {jobs.map(job => (
                <>
                 <div key={job._id} className='jobs'>
                  <h2>{job.jobOrInternshipTitle}</h2>
                  <p>{job.jobOrInternshipCompany}</p>
                 <div className='small-div'>
                    <div className='exp'><MdOutlineWorkOutline /> {job.jobOrInternshipExperience}</div>
                    <div className='exp'><IoLocationOutline /> {job.jobOrInternshipLocation}</div>
                  </div>
                  <h4>Job Description</h4>
                  <div className='desc'> {job.jobOrInternshipDescription}</div>
                  <div className='applybtn'>
                    <a href={job.jobOrInternshipCompanyWeb} target='_blank'>Apply</a>
                  </div>
                </div>
                </>
               ))}

<div className='companieslog'>
                    
                     <img src={Com}/>
                  </div>
               
              </div>
            </div>
         </div>
    </div>
     <Start />
    </>
   
  );
};

export default Internship2;
