import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Rocket from '../../../assets/2.0/media/rocket.svg';
import { CiCalendarDate } from "react-icons/ci";
import { BiCurrentLocation } from "react-icons/bi";
import { FiUsers } from "react-icons/fi";

const Upcoming2 = () => {
  const [hackathons, setHackathons] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.multigrad.in/open/hackathons');
        setHackathons(response.data.data);
      } catch (error) {
        console.error('Error fetching hackathons:', error);
      }
    };

    fetchData();
  }, []);

  const openWhatsAppGroup = () => {
    window.open('https://play.google.com/store/apps/details?id=com.multigrad.app', '_blank');
  };

  return (
    <>
      <div className='upcoming'>
        {hackathons.map((hackathon) => {
          const startDate = new Date(hackathon.hackathonStartTime);
          const currentDate = new Date();
          const isFutureDate = startDate > currentDate;

          return (
            <div key={hackathon._id} className='hc-div'>
              <img src={hackathon.hackathonBanner} alt={hackathon.hackathonName} />
              <h2>{hackathon.hackathonName}</h2>
              <div className='runs'>
                <div className='rn-d'>
                  <div className='rn-inner'>
                    <CiCalendarDate /> Start :  {hackathon.hackathonStartTime}
                  </div>
                  <div className='rn-inner'>
                    <FiUsers /> Participants :  {hackathon.participants.length}
                  </div>
                </div>
                <div className='rn-d'>
                  <div className='rn-inner'>
                    <BiCurrentLocation /> Mode : {hackathon.hackathonMode}
                  </div>
                </div>
              </div>
              <div className='btn'>
                {isFutureDate ? (
                  <button onClick={openWhatsAppGroup}>Register on App</button>
                ) : (
                  <button onClick={openWhatsAppGroup}>Register on App</button>
                )}
              </div>
            </div>

          );
        })}

        
      </div>
    </>
  );
};

export default Upcoming2;
