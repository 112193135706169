import React from 'react';
import Users from '../../../assets/2.0/media/userssvg.svg';
import CM from '../../../assets/2.0/media/cm.svg';
import Start from '../common/Start';
import ButtonTop from '../common/Button';
import Upcoming2 from '../common/Upcoming';


const Home2 = () => {
  return (
    <div className='home-container'>
        <div className='container line-container'>
            <div className='tagline'>
                <h1>"Ready to <br/>build <span id="white">your career?"</span></h1>
                <p>Navigate your college journey seamlessly - Explore, Learn, Placed! - Multigrad</p>
               <ButtonTop />
                <div className='btn-two'>
                    <img src={Users}/>
                    <p>10,000+ students across india using multigrad!</p>
                </div>
                <div className='flex-box flex-row'>
                    <div className='boxes'>
                        <h3>Campus connect</h3>
                        <p>Seamlessly connect with students, faculty, and campus.</p>
                    </div>
                    <div className='boxes'>
                        <h3>Network & more</h3>
                        <p>Explore inter-college network,events , hackathons. and more.</p>
                    </div>
                    <div className='boxes'>
                       <h3>Internship & Jobs</h3>
                       <p>Discover 40,000+ curated jobs & internships.</p>
                    </div>
                    <div className='boxes'>
                    <h3>Portfolio</h3>
                    <p>Beautiful portfolio websites. Free with Creative Templates.</p>
                    </div>
                </div>
            </div>
        </div>
   
        <div className='highlighted multi'>
            <div className='container'>
            <h1>Happening now!</h1>
                     <Upcoming2 />
            </div>
       
        </div>

        <div className='container line-container'>
            <div className='tagline'>
                <h1>Become a <span id="white">Campus Star"</span></h1>
                <p>And get awesome goodies, referrals and chance to feature on multigrad.</p>
            </div>
            <div className='flex-mobile flex-row'>
                    <div className='mg-50 text-w'>
                    <h1>A Showcase of Your Leadership Qualities</h1>
                    <p>Offer your weekends, side projects, hobby endeavors, and serious ventures a platform to thrive. Create a space where collaborators are welcomed, and inspire fellow builders.</p>
                   <a href="/become-a-leader" id="btn">Explore now</a>
                </div>
                <div className='mg-50'>
                <div className='flex-box flex-row'>
                    <div className='boxes img'>
                        <img src={CM}/>
                    </div>
                    </div>      
                </div>      
                </div>
        </div>
        
         <Start />
    </div>
  );
};

export default Home2;
