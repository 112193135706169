import React, { useState, useEffect } from "react";
import Users from '../../../assets/2.0/media/userssvg.svg';
import Team from '../../../assets/2.0/media/team.svg'
import axios from "axios";
import ButtonTop from "../common/Button";
import Commun from "../common/Community";
import { FaUsers } from "react-icons/fa";
import { MdEmojiEvents } from "react-icons/md";
import { FaCode } from "react-icons/fa";
import { MdWork } from "react-icons/md";
import { FaNetworkWired } from "react-icons/fa";
import { TfiWorld } from "react-icons/tfi";

const Leader = () => {

    const handleStartFree = () => {
        window.open("https://play.google.com/store/apps/details?id=com.multigrad.app", '_blank');
    };
    const [data, setData] = useState([]);
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `https://api.multigrad.in/secure/contributor`,
            {}
          );
          setData(response.data.data);
        } catch (error) {
          console.log(error);
        }
      };
      fetchData();
    }, []);
   
    const joinCommunity = () => {
        window.open("https://chat.whatsapp.com/KSZ38cardc5DwGCRqkebmI", '_blank');
    };



  return (
    <>
     <div className='home-container'>
        <div className='container line-container'>
            <div className='tagline'>
                <h1>Represent us in your <span id="white">organisation</span></h1>
                <p>and get awesome goodies,certificate, referrals and chance to feature on multigrad. </p>
               <ButtonTop />
                <div className='btn-two'>
                    <img src={Users}/>
                    <p>10,000+ students across india using multigrad!</p>
                </div>
            </div>
        </div>
    </div>
    <div className="container">
    <div className="mg-peoples">
            {data.map((peoples) => (
              <a
                href={peoples.linkedin}
                target="_blank"
                key={peoples.id}
                id={peoples.fullName}
              >
                <div className="mg-profiles">
                  <img src={peoples.profilePic} alt={peoples.fullName} />
                  <p>{peoples.role}</p>
                  <button disabled>
                    {peoples.fullName}{" "}
                  
                  </button>
                </div>
              </a>
            ))}
          </div> 
    </div>
    <div className='footer' >
            <div className="highlighted white com">
            <h1 id="headd">Our Active Community</h1>
             <Commun />
            </div>
         </div>



    <div className='footer black' >
            <div className="auto highlighted">
                <div className='container'>
                <h1>Become a campus<br /><span id="white">Leader!</span></h1>
                    <div className='flex-mobile flex-row'>
                    <div className='mg-100'>
                    <h2 id="fullText">What does a Lead do?</h2>
                   <div className='mg-row leads-benefits'>
                   <div className='leads'>
                   <FaUsers />
                   <h2>Start or grow a club</h2>
                     <p>Work with your university to start a student club. Create a core team and select a faculty advisor to support.</p>
                   </div>
                   <div className='leads'>
                   <MdEmojiEvents />

                     <h2>Host workshops, Events & Hackathons</h2>
                     <p>Grow student knowledge on developer products and platforms through hands-on workshops and events.</p>
                   </div>
                   <div className='leads'>
                   <FaCode />

                     <h2>Build projects</h2>
                     <p>Identify local partners to work with and lead project building activities.</p>
                   </div>
                   <h2 id="fullText">Benefits of being a Lead</h2>
                   <div className='leads'>
                   <MdWork />

                     <h2>Professional growth</h2>
                     <p>Access to community management training and technical knowledge. Invitations to selected Google events.</p>
                   </div>
                   <div className='leads'>
                   <FaNetworkWired />

                     <h2>Network growth</h2>
                     <p>Access to a global network of student leaders, professional community organizers, & industry experts</p>
                   </div>
                   <div className='leads'>
                   <TfiWorld />
                     <h2>Community Learning</h2>
                     <p>Dedicated support to help educate and expand community online and in-person.</p>
                   </div>
                   </div>
                </div>
                <div className='mg-40'>
                   
                </div>      
                </div>
                </div>
            </div>
         </div>
         <div className='footer' >
            <div className="highlighted">
                <div className='container'>
                    <div className='flex-mobile-rev flex-row'>
                    <div className='mg-60'>
                    <h1>Become a part of <br /><span id="white">top 1% team!</span></h1>
                    <div className='btn-two light'>
                         <a href="https://blog.multigrad.in/?p=123"><button id="primary-btn">Know more</button></a>
                         <a href=""><button id="secondary-btn">Apply</button></a>
                       
                         
                </div>
                </div>
                <div className='mg-40'>
                    <img src={Team} id="team" />
                </div>      
                </div>
                </div>
            </div>
         </div>
  
      
    
   
         
        
    </>
   
  );
};

export default Leader;
