import React from 'react';
import { NavLink } from 'react-router-dom'; 
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import LogoLight from '../../../assets/2.0/media/logo_white.svg';
import Menu from '../../../assets/2.0/media/menu.svg';


const SponsorHeader = () => {
  const handleStartFree = () => {
    window.open("https://play.google.com/store/apps/details?id=com.multigrad.app", '_blank');
};
const handleNavLinkClick = () => {
  window.location.reload(); // Refresh the page
};
  return (
    <>
      {/* Desktop Navbar */}
      <Navbar bg="" expand="lg" className='nav2 desktop-navbar'>
        <Container id="navTop">
          <Navbar.Brand href="/">
            <img src={`https://api.multigrad.in/open/media/?file=1723150310638.png`} alt="multigrad.in" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              navbarScroll
            >
              <NavLink to="/" exact className="nav-link" activeClassName="active">
                Home
              </NavLink>
              <NavLink to="/internship" className="nav-link" activeClassName="active">
                Work
              </NavLink>
              <NavLink to="/internship" className="nav-link" activeClassName="active">
                Clubs
              </NavLink>
              <NavLink to="/mentorship" className="nav-link" activeClassName="active">
                Mentors
              </NavLink>
              <NavLink to="/become-a-leader" className="nav-link" activeClassName="active">
                Become a leader <span id="new">Apply</span>
              </NavLink>
            </Nav>
            <div className="d-flex header-btn">
              <a target='_blank' id="secondary-btn">Login</a>
              <button id="primary-btn">Start for free</button>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className='comingsoon'>
      <a
  href="https://play.google.com/store/apps/details?id=com.multigrad.app"
  class="luma-checkout--button"
  data-luma-action="checkout"
  data-luma-event-id="evt-GC7xlprkz2cs1TR"
>
  Register for Event
</a>

<script id="luma-checkout" src="https://embed.lu.ma/checkout-button.js"></script>
        </div>
      <Navbar bg="" expand="lg" className='nav2 mobile-navbar'>
       
        <Container id="navTop">
          <Navbar.Brand href="/">
            <img src={`https://api.multigrad.in/open/media/?file=1723150310638.png`} alt="multigrad.in" />
          </Navbar.Brand>
         
          
            <Navbar.Toggle aria-controls="navbarScroll" >
               <img id="menu" src={Menu}/>
            </Navbar.Toggle >
           
      
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              navbarScroll
            >
              <NavLink to="/" exact className="nav-link" activeClassName="active">
                Home
              </NavLink>
              {/* <NavLink to="/internship" className="nav-link" activeClassName="active">
                Work
              </NavLink> */}
              <NavLink to="/clubs" className="nav-link" activeClassName="active">
                Clubs <span id="new">Trending⚡️</span>
              </NavLink>
              <NavLink to="/mentorship" className="nav-link" activeClassName="active">
                Mentors
              </NavLink>
              <NavLink to="/become-a-leader" className="nav-link" activeClassName="active">
                Become a leader <span id="new">Apply</span>
              </NavLink>
            </Nav>
            <div className="d-flex header-btn">
              {/* <a target='_blank' id="secondary-btn">Login</a> */}
              <button id="primary-btn" onClick={handleStartFree}>Start for free</button>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default SponsorHeader;
