import "./assets/css/App.css";
import "./assets/css/Dark.css";
import "./assets/css/hackathon.css";
import "./assets/css/mobile.css";
import "./assets/2.0/css/style.css"
import UserProfile from "./screens/publicProfile/index";
import Header2 from "./screens/2.0/common/Header";
import Home2 from "./screens/2.0/home";
import Internship2 from "./screens/2.0/home/internship";
import { Routes, Route, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { JobProvider } from "./screens/2.0/context/JobContext";
import React, { useEffect } from "react";
import Footer2 from "./screens/2.0/common/Footer";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Roadmap2 from "./screens/2.0/home/Roadmap";
import Mentorship2 from "./screens/2.0/home/mentorship";
import Leader from "./screens/2.0/home/Leader";
import Privacy2 from "./screens/2.0/home/Privacy";
import Terms2 from "./screens/2.0/home/Terms";
import Clubs2 from "./screens/2.0/home/Clubs";
import EventPage from "./screens/2.0/common/EventPage";
import SponsorHeader from "./screens/2.0/common/SponserHeader";


function App() {
  const location = useLocation();
  useEffect(() => {
   
    AOS.init({
      duration: 300, 
    });

    AOS.refresh();
  }, []);
  useEffect(() => {
    if (location.pathname.slice(1).charAt(0).toUpperCase()) {
      document.title =
        location.pathname.slice(1).charAt(0).toUpperCase() +
        location.pathname.slice(2) +
        " | Multigrad";
    } else {
      document.title = "Ready to build career | Multigrad";
    }
  }, [location]);

  return (
    <>
    
      <Routes>
        <Route path="/:userName" element={<>  <UserProfile /></>} />

        {/* web 2.0 coomon component  */}
         <Route path="/" element={<> <Header2 />
         <Home2 />
         <Footer2 /> </>} />
         <Route path="/internship" element={<> <Header2 /> <JobProvider><Internship2 /> </JobProvider><Footer2 /> </>} />
         <Route path="/roadmaps" element={<> <Header2 /><Roadmap2 /><Footer2 /> </>} />
         <Route path="/mentorship" element={<> <Header2 /><Mentorship2 /><Footer2 /> </>} />
         <Route path="/clubs" element={<> <Header2 /><Clubs2 /><Footer2 /> </>} />
         <Route path="/become-a-leader" element={<> <Header2 /><Leader /><Footer2 /> </>} />
         <Route path="/privacy-policy" element={<> <Header2 /><Privacy2 /><Footer2 /> </>} />
         <Route path="/terms-and-condition" element={<> <Header2 /><Terms2 /><Footer2 /> </>} />
         <Route path="/gla-cyberonites" element={<> <SponsorHeader /><EventPage /><Footer2 /> </>} />

        {/* web 2.0 coomon component  */}
       
       
      </Routes>
      
    </>
  );
}

export default App;
