import React from 'react';

const ButtonTop = () => {
  
  const handleScroll = () => {
    const element = document.getElementById('register');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <div className='btn-two'>
        <button id="primary-btn" onClick={handleScroll}>Scroll Down & Register for Event</button>
      </div>
    </>
  );
};

export default ButtonTop;
