import React from 'react';
import Rocket from '../../../assets/2.0/media/rocket.svg';
const GLAStart = () => {
    const handleStartFree = () => {
        window.open("https://cyberonites.com/", '_blank');
    };
    const JoinComunity = () => {
        window.open("https://chat.whatsapp.com/KSZ38cardc5DwGCRqkebmI", '_blank');
    };

  return (
   <>
     <div className='footer' >
            <div className="highlighted">
                <div className='container'>
                    <div className='flex-mobile flex-row'>
                    <div className='mg-60'>
                    <h1>Ready to join <br /><span id="white">cyberonites club ?</span></h1>
                    <div className='btn-two light'>
                         <button id="primary-btn" onClick={handleStartFree}>For more details</button>
                         
                </div>
                </div>
                <div className='mg-40'>
                    <img src={Rocket} />
                </div>      
                </div>
                </div>
            </div>
         </div>
   </>
  );
};

export default GLAStart;
