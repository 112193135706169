import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MdOutlineWorkOutline } from "react-icons/md";

const Clubs = () => {
  const [hackathons, setHackathons] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.multigrad.in/open/community');
        setHackathons(response.data.data);
        console.log(response.data.data)
      } catch (error) {
        console.error('Error fetching hackathons:', error);
      }
    };

    fetchData();
  }, []);

  const openWhatsAppGroup = () => {
    window.open('https://play.google.com/store/apps/details?id=com.multigrad.app', '_blank');
  };

  return (
    <>
      <div className='upcoming club'>
        {hackathons.map((hackathon) => {
          const startDate = new Date(hackathon.hackathonStartTime);
          const currentDate = new Date();
          const isFutureDate = startDate > currentDate;

          return (

            <>
            
            
           
            <div key={hackathon._id} className='clubs mt-div'>
              <img src={hackathon.communityBanner} alt={hackathon.fullName} />
            
            
               <div className='ct-cp'>
                <a href="https://play.google.com/store/apps/details?id=com.multigrad.app">
               <h2>{hackathon.channelName}</h2>
                </a>
                </div>
            
            </div>
         
        
            </>
          );
        })}
      </div>
    </>
  );
};

export default Clubs;
