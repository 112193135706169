import React from "react";
import Container from "react-bootstrap/Container";
import Users from '../../../assets/2.0/media/userssvg.svg';
import ButtonTop from "../common/Button";
function Terms2() {
  return (
    <div className='home-container'>
         <div className='container line-container'>
            <div className='tagline'>
                <h1><span id="white">Terms & Condition</span></h1>
               
            </div>
        </div>

       
<div className="highlighted white">
        <div className="container">
        <h3>I. INTRODUCTION</h3>
          <p>
            The Website/App www.multigrad.in (“”) is operated by Fightage
            Private Limited, a private limited company incorporated under the
            Companies Act, 2022 (“Multigrad”) having its registered office at
            C/O Prem Lata Sishat Etawah Uttar Pradesh India - 206245. Use of the
            Website/App and its services is governed by these Terms of Use
            (“Terms”). By using the Website/App, users signify they have
            carefully read, understood and agree to be bound by these Terms.
            When users transact or browse through the Website/App or avail any
            service provided by the Website/App, in present or future, users
            accept and agree to be bound by these Terms, policies, guidelines
            and conditions of Multigrad’s service. These Terms could be changed,
            altered, edited, amended, modified, reviewed, revised or substituted
            by Multigrad at any time with or without prior notice and users are
            requested to read the Terms before using or accessing the
            Website/App.
          </p>
          <h3>II. PLATFORM SERVICES</h3>
          <p>
            This a platform that users utilise to communicate and transact with
            one another. Multigrad is not and cannot be a party to or control in
            any manner any transaction between users of the Website/App.The
            purchase of products or services on the are governed by the terms
            and condition of sale. This is only a platform that can be used by
            users to reach a larger base to buy and sell products. Multigrad is
            only providing a platform for communication and the contract for
            sale of the products shall be a strictly bipartite contract between
            the buyer and the seller. At no time shall Multigrad hold any right,
            title or interest over the products or have any obligations or
            liabilities in respect of such bipartite contract entered into
            between the buyer and seller. Users may upload and display the
            images and information of their products offered for sale on the
            Website/App’s online platform. Such display of the user’s products
            or related information on the Website/App in no manner can be
            treated or construed as an endorsement or advertisement of such
            products by Multigrad. Provision of a specific service through the
            online platform of the Website/App will be subject to users agreeing
            and consenting to certain additional terms, rules, policies,
            procedures, guidelines, disclaimers, notices or otherwise, if any,
            as applicable to such service (“Additional Terms“) and if there is
            any conflict between the Terms of Use and the Additional Terms, the
            Additional Terms shall take precedence in relation to that specific
            service.
          </p>
          <h3>III. ELIGIBILITY</h3>
          <p>
            The user should have completed 18 years of age and be competent to
            enter into a contract under Indian contract law. Any user under 18
            years of age who is intending to avail the services of the
            Website/App should do so through his or her legal guardian in
            accordance with applicable law. If any person is using the
            Website/App on behalf of any corporation, company, institution,
            association or other entity, the person should be authorized by such
            corporation, company, institution, association or other entity to
            use and access the Website/App. Multigrad reserves the right to
            refuse access to use the services offered at the to new users or to
            terminate access granted to existing users at any time without
            according any reasons for doing so.
          </p>
          <h3>IV. USER ACCOUNT</h3>
          <p>
            The user may browse the Website/App without registering with
            Multigrad. The user has to set up an account with Multigrad before
            availing any online services provided through the Website/App. The
            user can create an account by entering an email address and password
            or by using any permitted social media Website/App account to sign
            in (“Account Information”) or using a mobile number. The email
            address provided is verified by Multigrad however, it is advisable
            for the user to provide a valid email address. The Website/App will
            set-up an account and email the details of the account to the user
            at the email address provided. A user can then access the account on
            the Website/App. A user can also create and update an account
            through Customer Support A user can create one user account only and
            no user is permitted to transfer or interchange the User Account.
            The list of user information required to be provided by the user,
            the manner of usage, protection and confidentiality of user
            information and account information are more specifically dealt
            under the Privacy Policy and the users are requested to read in
            detail the Privacy Policy before sharing user information or
            creating an account
          </p>
          <h3>V. USER JURISDICTION</h3>
          <p>
            If Multigrad is used from outside India, it is entirely at the
            user’s risk. The makes no representation that its contents and other
            features are available or otherwise suitable for use outside India.
            If the Website/App is used and accessed from or in locations outside
            India, the user shall ensure that the usage of the is legal and
            valid under applicable law of the user’s location. The user agrees
            to undertake all liabilities, responsibilities and consequences
            under applicable law in connection with the use of the Website/App
            from or in any location outside India. The user accessing the from
            any location outside India agrees and consents to enter into
            separate or additional terms as may be required by Multigrad at any
            time.
          </p>
          <h3>VI. USER COVENANTS</h3>
          <p>
            The user agrees to use the and the materials provided on the only
            for purposes that are permitted by these Terms and any applicable
            law. The user shall not use or access the in a manner that
            prejudicially affects the interests and goodwill of the Website/App,
            Multigrad or its affiliates or associates. The user agrees and
            undertakes that the reviews, comments and other information provided
            or posted or uploaded on the are not false, incomplete, distorted,
            manipulated, fraudulent or misleading. By way of example, and not as
            a limitation, user agrees and undertakes that when using or
            accessing the Website/App, user will not host, display, upload,
            modify, transmit, update or share any information (“Objectionable
            Content”) that:
            <br />
            1- belongs to another person and to which the user does not have any
            right to,
            <br />
            2- is grossly harmful, harassing, blasphemous, defamatory, obscene,
            pornographic, paedophilic, libellous, invasive of another’s privacy,
            hateful, or racially, ethnically, objectionable, disparaging,
            relating or encouraging money laundering or gambling or otherwise
            unlawful in any manner whatsoever,
            <br />
            3- harms minors in any way,
            <br />
            4-infringes any patent, trademark, copyright or other proprietary
            rights,
            <br />
            5- violates any law for the time being in force,
            <br />
            6- deceives or misleads the addressee about the origin of such
            messages or communicates any information, which is grossly offensive
            or menacing in nature,
            <br />
            7- impersonate another person,
            <br />
            8- contains software viruses or any other computer code, files or
            programs designed to interrupt, destroy or limit the functionality
            of any computer resource, threatens the unity, integrity, defence,
            security or sovereignty of India, friendly relations with foreign
            states, or public order or causes incitement to the commission of
            any cognisable offence or prevents investigation of any offence or
            is insulting to any other nation.
            <br />
            The user shall not use any deep-link, robot, spider or other
            automatic device, program, algorithm or methodology, or any similar
            or equivalent manual process, to access, acquire, copy or monitor
            any portion of the Website/Content or its content, or in any way
            reproduce or circumvent the navigational structure or presentation
            of the Website/App, materials or its content, to obtain or attempt
            to obtain any materials, documents or information through any means
            not specifically made available through the . The user may not
            access (or attempt to access) any of the services by any means other
            than through the interface that is provided through the Website/App,
            unless user have been specifically allowed to do so in a separate
            agreement with Multigrad. The user should not do anything that
            disables, overburdens, or impair the proper functioning of the
            Website/App. Users should specifically agree that they will not
            engage in any activity that interferes with or disrupts the services
            or the servers and networks which are connected to the services
            provided through the Website/App. The user undertakes not to attempt
            to circumvent or modify any security technology or software that is
            part of the Website/App. The user also agrees not to engage or
            assist any other person, to circumvent or modify any security
            technology or software that is part of the Website/App. The user
            should also not obscure or obstruct any features of the Website/App.
            The user undertakes not to copy, modify, create a derivative work
            of, reverse engineer, decompile or otherwise attempt to extract the
            source code of the Website/App or any part thereof without a
            specific written permission from Multigrad to do so. The lists
            multiple products for sale offered by sellers and hosts comments of
            numerous users and it is not possible for Multigrad to be aware of
            the contents of each product listed for sale, or each comment or
            review that is displayed. The user acknowledges and agrees that by
            accessing or using the Website/App or availing the services, user
            may be exposed to content that the user may consider offensive,
            indecent or otherwise objectionable. Multigrad disclaims all
            liabilities arising in relation to such Objectionable Content on the
            Website/App. Any user if exposed to such Objectionable Content as
            aforesaid is requested to immediately report the same to the
            Grievance Officer in the manner provided under these Terms and the
            Website/App will endeavour to remove such content as soon as
            possible after investigation. The user agrees and acknowledges that
            Multigrad is not the seller of the products and Multigrad shall in
            no manner be deemed to be the seller of the products on the
            Website/App. Multigrad is only facilitating purchase of the products
            by the user from the vendors by providing use and access to the
            Website/App. The user expressly consents to receive communications
            from Multigrad in relation to the user’s use and access of the
            Website/App and its services and agrees that such communications
            shall not be treated as an unsolicited communication. The user
            agrees that Multigrad may, at any time, modify or discontinue all or
            part of the Website/App, charge, modify or waive fees required to
            use the Website/App, or offer opportunities to some or all users.
          </p>
          <h3>VII. INFRASTRUCTURE</h3>
          <p>
            To use the Website/App users require Internet connectivity or
            appropriate telecommunication links and the service does not include
            the provision of any computer system, resource, terminal, server,
            equipment, mobile or any devices. Multigrad shall not have any
            responsibility or liability for any computer system, resource,
            terminal, server, equipment, mobile or any devices necessary for
            user to use and access the nor shall be liable for any costs
            incurred on account of the user’s Internet or appropriate
            telecommunication links usage. Multigrad would not be liable for
            damages, viruses or other code that may affect the user’s computer
            system, resource, terminal, server, equipment, mobile or any other
            devices while using the and Multigrad does not ensure that the
            Website/App will be compatible with all hardware and software used
            by the user. The user must comply with applicable third party terms,
            if any, such as mobile device agreement or wireless data service
            agreement etc. while using the Website/App.
          </p>
          <h3>VIII. USE OF CONTENT</h3>
          <p>
            Any content that the user posts or material it submits, the user
            grants Multigrad and its affiliates and associates a non-exclusive,
            royalty-free, irrevocable, perpetual and fully sub-licensable rights
            to use, reproduce, publish, distribute and display such content
            throughout the world in any mode medium or manner now known or
            developed in future; and the right to use the name that the user
            submits in connection with such content, if the user chooses. The
            user agrees that the rights the user grants as above are irrevocable
            during the entire period of protection of the user’s intellectual
            property rights associated with such content and material. The user
            agrees to waive the user’s right to be identified as the author of
            such content and materials and the user’s right to object to
            derogatory treatment of such content or material. The user agrees to
            perform all further acts and execute deeds and documents necessary
            to perfect the above rights granted by the user to Multigrad. The
            user represents and warrants that the user owns or otherwise
            controls all of the rights to the content and material that the user
            posts or that the user otherwise provides on or through the ; that,
            as at the date that the content or material is submitted to the :
            (i) the content and material is accurate; (ii) use of the content
            and material does not breach any applicable Indian law or the
            policies or guidelines or Additional Terms (iii) the content and
            material do not breach these Terms and Privacy Policy.
          </p>
          <h3>IX. THIRD PARTY WEBSITE/APPS</h3>
          <p>
            The Website/App may contain links to other Website/Apps and the
            Website/App are not in any way responsible for the terms of use or
            content of such Website/Apps and expressly disclaims all liability
            associated with the content and use of these Website/Apps. The
            linked Website/Apps are not under the control of Multigrad and users
            are encouraged to read the terms of use and privacy policy of each
            and every linked Website/App. If users decide to access any of the
            third-party Website/Apps linked to the Website/App, it is at the
            risk of the users to access and use such linked Website/Apps and
            Multigrad shall not be responsible in any manner whatsoever.
          </p>
          <h3>X. INTELLECTUAL PROPERTY RIGHTS</h3>
          <p>
            All content included on the Website/App, such as text, graphics,
            logos, button icons, images, audio clips, digital downloads, data
            compilations, and software, is the property of Multigrad, its
            affiliates, associates or its content suppliers and is protected by
            India and international copyright, authors’ rights and database
            right laws. The compilation of all content on the Website/App is the
            exclusive property of Multigrad, its affiliates, associates or its
            content suppliers and is protected by laws of India and
            international copyright and database right laws. All software used
            on this is the property of Multigrad, its affiliates, associates or
            its software suppliers and is protected by laws of India and
            international copyright authors rights and database laws. Multigrad
            grants the user a limited licence to access and make personal use of
            this Website/App, but not to download (other than page caching) or
            modify it, or any portion of it, except with express written consent
            of Multigrad . This licence does not include any resale or
            commercial use of the contents of the Website/App; any collection
            and use of any product listings, descriptions, or prices; any
            derivative use of this or its contents; any downloading or copying
            of account information for the benefit of another seller. The
            trademarks, logos and service marks displayed on the Website/App
            (“Marks”) are the property of Multigrad or its affiliates,
            associates or its content suppliers or users or respective third
            partis. Users are not permitted to use the Marks without the prior
            consent of Multigrad, its affiliates, associates or its content
            suppliers or users or the third party that may own the Marks.
            Multigrad owns all intellectual property rights to and into the
            trademark “Multigrad,” and the Website/App, including, without
            limitation, any and all rights, title and interest in and to
            copyright, related rights, patents, utility models, designs,
            know-how, trade secrets and inventions, goodwill, source code, meta
            tags, databases, text, content, graphics, icons, and hyperlinks. The
            users agree that they shall not use or apply for registration of any
            marks, or domain names which are similar to the marks or domain
            names used in connection with the or owned by Multigrad. No user
            shall use, copy, reproduce, modify, alter, change, amend, transmit,
            broadcast, edit, revise, review, adapt, translate, distribute,
            perform, display, sell or otherwise deal with content or the
            intellectual property rights of the Website/App or content suppliers
            or third parties in any mode medium or manner now known or developed
            in future without authorisation from Multigrad and on happening of
            any such event Multigrad reserves the right to immediately
            discontinue the Website/App services to such user without prejudice
            Multigrad’s right to initiate legal action against such user. Users
            are strictly prohibited from framing or use framing techniques to
            enclose any content or intellectual property on the Website/App to
            illegally and unlawfully exploit the content or intellectual
            property rights owned by Multigrad or content suppliers or third
            party as the case is.
          </p>
          <h3>XI. FEE</h3>
          <p>
            Users can use and access the Website/App free of charge. However, at
            any time in future, after duly notifying the users in advance,
            Multigrad reserves its right to charge the users for the use of the
            Website/App and provision of the services through the Website/App
            and all taxes as per applicable Indian law.
          </p>
          <h3>XII. NOTICE PROCEDURE</h3>
          <p>
            If any user believes that any Objectionable Content is hosted on the
            Website/App or the user’s rights are being infringed, users shall
            immediately inform the Grievance Officer by following the Notice
            Procedure mentioned below. The Website/App will endeavour to remove
            such content as soon as possible after investigation. No untrue or
            false information or allegation shall be made by the user and if any
            user is found misusing the notifying procedure, the reserves the
            right to terminate such user’s account and shall take appropriate
            legal action against the user. Upon receipt of a notice, Multigrad
            will take appropriate steps for removing the infringing or
            Objectionable Content all of which are taken without any admission
            as to liability and without prejudice to any rights, remedies or
            defenses, all of which are expressly reserved. Multigrad shall also
            endeavour to forward a copy of the user’s notice to the alleged
            infringer or defaulter. Furthermore, in submitting a notice, the
            user grants to Multigrad the right to use, reproduce, publish, and
            display its content throughout the world in any mode medium or
            manner now known or developed in future. This includes forwarding
            the notice to the parties involved in the provision of the allegedly
            infringing content. The User agrees to indemnify Multigrad for all
            claims brought by a third party against Multigrad arising out of or
            in connection with the submission of a notice. User further
            undertakes that the contents of the such notice are true and correct
            to the best of user’s knowledge and belief and undertake to subject
            to any legal action, civil and criminal, if proven otherwise. It is
            repeated and reiterated that Multigrad’s role and responsibility is
            completed on taking down the infringing or Objectionable Content as
            alleged in the notice and Multigrad shall in no manner be liable for
            or in relation to the infringing or Objectionable Content. Multigrad
            shall in no manner be involved in trying, contesting, mediating,
            negotiating or conciliating the dispute or differences between the
            user who notifies the objections and the user against whom the
            objections are raised.
          </p>
          <h3>XIII. DISCLAIMER</h3>
          <p>
            The User expressly agrees that the use of the Website/App is at the
            user’s sole risk. The Website/App and the contents uploaded or
            provide or shared by other users are made available to the user
            through the Website/App on an “AS IS” basis and “AS AVAILABLE BASIS”
            without any warranty of any kind, express, implied, statutory or
            otherwise, including the implied warranties of title,
            non-infringement, suitability, accuracy, reliability, completeness,
            timeliness, performance, safety, merchantability or fitness for a
            particular purpose or the legality of the products listed or
            displayed or transacted to the extent permitted by applicable Indian
            law and Multigrad does not accept any liability for any errors or
            omissions. Multigrad shall not be liable or responsible for any
            actions or inactions of sellers or any breach of conditions,
            representations or warranties by the sellers or manufacturers of the
            products and expressly disclaims any and all responsibility and
            liability in that regard. Multigrad shall not mediate or resolve any
            dispute or disagreement between the user and the sellers or
            manufacturers of the products. Multigrad through the Website/App
            only provides a platform that users utilise to communicate and
            transact with one another and Multigrad does not endorse any
            products displayed on the Website/App or views expressed or
            information uploaded by any user. In no event Multigrad shall be
            liable for any information, content, materials, products or services
            available through the Website/App. The right, title, interest and
            ownership in the products that are transacted by the users through
            the Website/App does not vest with Multigrad and Multigrad holds no
            right, title or ownership over such products. The offer and
            acceptance of products through the Website/App is at the user’s sole
            risk and Multigrad accepts no liability for any errors or omissions
            or defaults or deficiency of any products either offered or
            acceptance by the users through the Website/App. Multigrad hereby
            disclaims any guarantees of exactness as to the finish and
            appearance of the product as ordered by and delivered to the user.
            The quality or quantity of any products, information or other
            material purchased or obtained by user through the Website/App may
            not meet user’s expectations. The Website/App could also be linked
            to third party Website/Apps and Multigrad assumes no responsibility
            for results obtained from the use of these Website/Apps. Multigrad
            makes no assurances that the Website/App is bug free and user
            friendly or the safety of the use of the Website/App. Multigrad
            including affiliates, associates and their respective owners,
            directors, officers, agents, shareholders and partners shall not be
            liable in any manner with respect to the loss or damage incurred or
            suffered by the users on account of virus attacks or bugs or other
            software or programmes that may be present or embedded while using
            or accessing the Website/App. Multigrad does not guarantee that the
            services provided through the Website/App will function without
            interruption or errors. In particular the operation of the services
            may be interrupted due to maintenance, updates, or system failures,
            virus attacks, bugs or due to any other technical reasons or
            otherwise. Website/App disclaims all liability for losses or damages
            caused by any such interruption or errors in functioning. Multigrad
            also disclaims all liability for any malfunctioning impossibility of
            access or poor use conditions of the Website/App due to
            inappropriate equipment, disturbances related to internet services,
            down time or otherwise. To the maximum extent permitted by
            applicable law, Multigrad will have no liability related to user
            content arising under intellectual property rights, libel, privacy,
            publicity, obscenity or other laws. Multigrad also disclaims all
            liability with respect to the misuse, loss, modification or
            unavailability of any user content. When the user deletes any kind
            of information the user must understand that the removed content may
            continue in backup copies. Multigrad will not be liable for any loss
            that the user may incur as a consequence of unauthorised use of the
            user account or account information in connection with the
            Website/App or any service or materials, either with or without the
            user’s knowledge. Multigrad shall not be liable for any third-party
            product, the advertisement available on e-mail or Website/App with
            respect to the third-party Website/App or the products and services
            are for information purpose only.
          </p>
          <h3>XIV. LIMITATION OF LIABILITY</h3>
          <p>
            In no event Multigrad including affiliates, associates and their
            respective owners, directors, officers, agents, shareholders and
            partners shall be liable for any loss or damage whatsoever including
            without limitation direct, indirect, punitive, incidental and
            consequential damages, lost profits, or damages resulting from the
            use or inability to use the Website/App whether based on warranty,
            contract, tort or in any other means or action. Without prejudice,
            it is agreed by the user that Multigrad including affiliates,
            associates and their respective owners, directors, officers, agents,
            shareholders and partners shall not be held cumulatively liable for
            any damages or claims in excess of INR 1,000/- (Rupees One Thousand)
            or the last payment made by the user; whichever is lesser, subject
            to applicable law.
          </p>
          <h3>XV. INDEMNITY</h3>
          <p>
            Users agree to indemnify and hold harmless Multigrad including
            affiliates, associates and their respective owners, directors,
            officers, agents, shareholders and partners from and against all
            actions, proceedings, claims, losses, damages and costs (including
            attorney fees) and other expenses suffered or incurred due to or
            attributable to or arising out of (i) violation of any applicable
            law (ii) breach of any representations, warranties or covenants of
            the user (iii) breach of the policies, guidelines and conditions of
            Multigrad’s service or Additional Terms or these Terms or Privacy
            Policy (including any modifications or additional notifications or
            instructions); (iv) infringement of any third party rights. This
            Clause survives the termination of these Terms.
          </p>
          <h3>XVI. TERMINATION</h3>
          <p>
            Multigrad reserves the right to terminate the services provided to
            any user without prior notice in the event of any default committed
            by user including but not limited to the breach of any applicable
            law, policies, guidelines and conditions of Multigrad’s service or
            Additional Terms or these Terms or the Privacy Policy (including any
            modifications or additional notifications or instructions). On such
            termination, the user’s information and other details shall be black
            listed by the Website/App and shall be taken into consideration
            whenever necessary. Multigrad will terminate the services provided
            to any user if it is required under any applicable law. Without
            prejudice, Multigrad reserves its right to terminate the services if
            such provision of services to the users is not economically viable
            for Multigrad. Users may choose to discontinue availing services and
            facilities provided through the Website/App by closing the user
            account with the Website/App. On termination of the user account,
            the user is not eligible to purchase or sell products through the
            Website/App. Once the user’s account is terminated for any reason
            whatsoever, the contents, reviews, comments or other material posted
            on the Website/App by the user shall be removed by Multigrad. Post
            termination of the services provided to the user through the
            Website/App, the user’s personal information would reside in the
            Website/App’s database for a certain period of time. It is also
            clarified that certain terms and conditions of the policies,
            guidelines and conditions of Multigrad’s service or Additional Terms
            or these Terms and the Privacy Policy continue to be binding on the
            user and if any user by default or otherwise is found using or
            dealing with the Website/App post termination of the service
            provided through the Website/App, the user shall be prosecuted by
            Multigrad. The user shall be also liable to compensate Multigrad for
            any unauthorised use of the Website/App.
          </p>
          <h3>XVII. MISCELLANEOUS</h3>

          <p>
            Severability: Each of the provisions in these Terms are severable
            and distinct from the others and if at any time one or more such
            provisions is or becomes invalid, illegal or unenforceable, the
            validity, legality and enforceability of the remaining provisions of
            these Terms shall not in any way be affected or impaired thereby.
            Captions: The various captions used in these Terms are for
            convenience purpose only and may not be used to interpret the
            provisions thereof. In case of captions and the related text
            conflicts, the text will prevail. Partial Invalidity: If any
            provision of these Terms is held to be illegal, invalid, or
            unenforceable under any present or future applicable Indian law, (i)
            such provision will be fully severable; (ii) these Terms will be
            construed and enforced as if such illegal, invalid, or unenforceable
            provision had never comprised a part hereof; (iii) the remaining
            provisions of these Terms will remain in full force and effect and
            will not be affected by the illegal, invalid, or unenforceable
            provision or by its severance here from; and (iv) in lieu of such
            illegal, invalid, or unenforceable provision, there will be added by
            Multigrad a legal, valid, and enforceable provision to the extent
            possible. Notices: For purposes of service messages and notices
            about the services to user, the Website may place a banner notice
            across its pages to alert the user to certain changes such as
            modifications to these Terms. Alternatively, notice may consist of
            an email from the Website to the user. The user agrees that the
            Website may communicate with user through the user’s account or
            through other means including email or mobile. Users are required to
            update their settings regularly to ensure that the Website’s service
            messages and notices are received by the users timely and
            accurately. Multigrad including affiliates, associates and their
            respective owners, directors, officers, agents, shareholders and
            partners shall have no liability for the non-receipt of the
            Website/App’s service messages and notices. Any user may contact
            Multigrad at the following address: To Grievance Officer corporate
            office – Fightage Private Limited, C/O Prem Lata Sishat Etawah Uttar
            Pradesh India - 206245. email – support@multigrad.com The user shall
            not assign its rights or obligations under these Terms and the
            Privacy Policy to a third party.
          </p>
          <h3>XVIII. GOVERNING LAW AND JURISDICTION</h3>
          <p>
            The terms and conditions of this agreement shall be governed by
            Indian law and the courts in Bangalore shall only have exclusive
            jurisdiction to resolve any disputes. In case of any complaints or
            grievances, the user may contact the Grievance Officer as under.
            User will be contacted with in 48 working hours for redressal.{" "}
            <br />
            Mr. Sandeep Shakya (Grievance Officer) <br />
            Email ID: support@multigrad.in <br />
            Address C/O Prem Lata Sishat Etawah Uttar Pradesh India - 206245.
            Fightage Private Limited (“Company/Us/We/Our”) is committed to
            protecting your privacy and ensuring you have a positive experience
            on Our website, www.multigrad.in (“Website/ Site”). This policy
            (“Policy”) outlines Our personal information handling practices for
            both online and offline data. If you give us personal information,
            we will treat it according to this Policy. By clicking “Sign Up”
            while registering on Our Website you (‘User/ You/ Your’) agree to be
            bound to the terms of this Policy and Terms of Services.
          </p>
        </div>
        </div>
      </div>
    
  );
}

export default Terms2;
